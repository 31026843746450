import { createGlobalStyle } from 'styled-components';

// language=LESS
const GlobalStyle = createGlobalStyle`
*:not(input):not(textarea) {
  /* disable text selection highlighting */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

/* this is because mercadopago's sdk opens an iframe and it
takes height in the screen */
body > iframe {
  display: none;
}

html,
body {
  font-size: 14px;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  padding: 0;
  margin: 0 !important;
  font-family: ${({ theme }) => theme.typography.default.fontFamily};
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    font-size: 12px;
  }

  button:focus,
  input:focus,
  textArea:focus,
  a:focus {
    outline: none;
  }

  & a,
  a:hover {
    text-decoration: none;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    background-color: #ffffff !important;
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  .modal__overlay {
    background-color: rgba(39, 50, 57, 0.3);
  }

}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(176, 176, 176);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(176, 176, 176); 
}

/* Modal */
.ReactModalPortal {
  z-index: 2;
}

.ReactModal__Body--open #courier-app {
  filter: blur(2px);
}

.Modal {
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  top: 45%;
  position: absolute;
  width: 80%;
  max-width: 570px;
  padding: 40px 20px;
  border-radius: 5px;
  box-shadow: ${({ theme }) => theme.shadows.main};
  background-color: ${({ theme }) => theme.colors.neutral.lightest};
  outline: 0;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, 0.3);
  z-index: 2;
}
`;

export default GlobalStyle;
