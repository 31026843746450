import React from 'react';
import PropTypes from 'prop-types';

import { LogisticMode } from '@app/utils/constants/logistic.constant';

const InitialDataContext = React.createContext({
  userId: null,
  partnerId: null,
  user: null,
  country: null,
});

export const useInitialData = () => {
  return React.useContext(InitialDataContext);
};

const InitialDataProviderComponent = ({ children, data }) => {
  return (
    <InitialDataContext.Provider
      value={{
        userId: data?.user?.id || null,
        partnerId: data?.user?.partnerId || null,
        user: data?.user || null,
        country: data?.country || null,
      }}
    >
      {children}
    </InitialDataContext.Provider>
  );
};

export const initialDataShape = {
  sessionId: PropTypes.string.isRequired,
  gtmKey: PropTypes.string.isRequired,
  googleMapsKey: PropTypes.string.isRequired,
  onlineSupportUrl: PropTypes.string.isRequired,
  timeNow: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    mobile: PropTypes.string,
    userType: PropTypes.string.isRequired,
    partnerId: PropTypes.string.isRequired,
    permission: PropTypes.string.isRequired,
    logisticMode: PropTypes.oneOf([LogisticMode.DROP_OFF, LogisticMode.CROSS_DOCKING]).isRequired,
  }).isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    shortName: PropTypes.string.isRequired,
    timeOffset: PropTypes.number.isRequired,
    timeZone: PropTypes.string.isRequired,
    currency: PropTypes.shape({
      id: PropTypes.number.isRequired,
      decimalPlaces: PropTypes.number.isRequired,
      isDeleted: PropTypes.bool.isRequired,
      isoCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      symbol: PropTypes.string.isRequired,
    }).isRequired,
    defaultCity: PropTypes.shape({
      latitude: PropTypes.number.isRequired,
      longitude: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

InitialDataProviderComponent.propTypes = {
  data: PropTypes.shape(initialDataShape).isRequired,
};

export const InitialDataProvider = InitialDataProviderComponent;
