import React from 'react';
import ContentLoader from 'react-content-loader';

const Loader = () => (
  <ContentLoader height="600px" width="100%" speed={2} preserveAspectRatio="xMinYMid slice">
    <rect x="0" y="0" rx="3" ry="3" width="48%" height="32" />
    <rect x="0" y="64" rx="3" ry="3" width="48%" height="64" />
    <rect x="0" y="144" rx="3" ry="3" width="48%" height="64" />
    <rect x="0" y="224" rx="3" ry="3" width="48%" height="64" />
    <rect x="51%" y="48" rx="3" ry="3" width="49%" height="96" />
    <rect x="51%" y="160" rx="3" ry="3" width="24%" height="32" />
    <rect x="51%" y="208" rx="3" ry="3" width="24%" height="32" />
    <rect x="51%" y="256" rx="3" ry="3" width="24%" height="32" />
    <rect x="76%" y="160" rx="3" ry="3" width="24%" height="32" />
    <rect x="76%" y="208" rx="3" ry="3" width="24%" height="32" />
    <rect x="76%" y="256" rx="3" ry="3" width="24%" height="32" />
  </ContentLoader>
);

export default Loader;
