import React from 'react';
import PropTypes from 'prop-types';
import AppsCoreBrandLoader from '@appscore/web-components/BrandLoader';

import BrandLoaderWrapper from './styled-components/BrandLoaderWrapper';

const BrandLoader = ({ fullScreen = false, minHeight = 'initial' }) => {
  return (
    <BrandLoaderWrapper data-testid="brand-loader" fullScreen={fullScreen} minHeight={minHeight}>
      <AppsCoreBrandLoader />
    </BrandLoaderWrapper>
  );
};

BrandLoader.propTypes = {
  fullScreen: PropTypes.bool,
  minHeight: PropTypes.string,
};

export default BrandLoader;
