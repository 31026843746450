import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import BrandError from '@app/components/BrandError';
import BrandLoader from '@app/components/BrandLoader';

const PublicEnvContext = React.createContext({});

export const getPublicEnv = async () => {
  const { data } = await axios.get('/api/courier-business-main/env');
  return data;
};

export const usePublicEnv = () => {
  return React.useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv }) => {
  const [publicEnv, setPublicEnv] = React.useState(context);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, []);

  const refreshPublicEnv = async () => {
    const result = await getPublicEnv();
    if (result?.initialData) {
      setPublicEnv(result);
    }
  };

  const value = useMemo(() => ({ publicEnv, refreshPublicEnv }), [publicEnv, refreshPublicEnv]);

  if (error) return <BrandError />;

  if (!publicEnv) return <BrandLoader fullScreen />;

  return <PublicEnvContext.Provider value={value}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  datetime: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    shortName: PropTypes.string.isRequired,
    currencyIsoCode: PropTypes.string.isRequired,
    currencySymbol: PropTypes.string.isRequired,
    timeOffset: PropTypes.number.isRequired,
  }).isRequired,
};
