import axios from '@app/services/axios';
import { BASE_URL } from '@app/utils/constants/service.constant';

async function getPartnerConfigurations({ params, config = {} }) {
  return await axios().get(`${BASE_URL}/partnerConfigurations`, {
    ...config,
    params,
  });
}

export default getPartnerConfigurations;
