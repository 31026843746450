let instance;

export const setInstance = (tracker, initialData) => {
  if (!instance) {
    instance = {
      track: tracker,
      initialData,
    };
  }
  return instance;
};

const NOT_SET = '(not_set)';
const track = (eventName, payloadReceived = {}) => {
  const { initialData } = instance;
  const payload = {
    ...payloadReceived,
  };
  if (!payload.partnerId) {
    payload.partnerId = initialData.user?.partnerId || NOT_SET;
  }
  if (!payload.userId) {
    payload.userId = initialData.user?.id || NOT_SET;
  }
  if (!payload.countryHostName) {
    payload.countryHostName = initialData.country?.name || NOT_SET;
  }
  instance.track(eventName, payload);
};

export default track;
