import styled from 'styled-components';

const mainWrapperMaxWidth = '1440px';

const MainWrapper = styled.div`
  width: 100%;
  max-width: ${mainWrapperMaxWidth};
  padding: 16px;
  margin: 0 auto;
`;

export default MainWrapper;
