import styled from 'styled-components';

const BrandLoaderWrapper = styled.div`
  width: 100%;
  min-height: ${({ fullScreen, minHeight }) => (fullScreen ? 'calc(100vh - 70px)' : minHeight)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

BrandLoaderWrapper.defaultProps = {
  minHeight: 'initial',
  fullScreen: false,
};

export default BrandLoaderWrapper;
