import React from 'react';
import { ThemeProvider as Provider } from 'styled-components';

import GlobalStyleSheet from '@app/styles/GlobalStyle';
import theme from '@app/styles/theme';

export const ThemeProvider = ({ children }) => {
  return (
    <Provider theme={theme}>
      <>
        <GlobalStyleSheet />
        {children}
      </>
    </Provider>
  );
};
