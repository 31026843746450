import styled from 'styled-components';
import MainWrapper from '@app/components/MainWrapper';

const ContentWrapper = styled(MainWrapper)`
  box-shadow: ${({ theme }) => theme.shadows.main};
  background-color: ${({ theme }) => theme.colors.neutral.lightest};

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 90%;
    box-shadow: ${({ theme }) => theme.shadows.main};
    border-radius: ${({ theme }) => theme.sizes.radius.md};
    margin-top: 3%;
    margin-bottom: 3%;
    font-family: ${({ theme }) => theme.typography.fontFamilies.lato};
  }
`;

export default ContentWrapper;
