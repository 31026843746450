import axios from 'axios';

import { Routes } from '@app/utils/constants/navigation.constant';

let instance = null;

function errorResponseHandler(error) {
  try {
    const status = error.response?.status;

    if (status === 401 || status === 403) {
      window.location.replace(Routes.SESSION_EXPIRED);
    }

    return Promise.reject(error);
  } catch {
    return Promise.reject(error);
  }
}

const errorInterceptor = axiosInstance => {
  axiosInstance.interceptors.response.use(response => response, errorResponseHandler);
};

export function initialize(props) {
  instance = (props && axios.create(props)) || axios.create();

  errorInterceptor(instance);

  return instance;
}

const getInstance = props => instance || initialize(props);

export default getInstance;
