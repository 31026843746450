import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '@appscore/web-components/theme';
import { BrandError as AppsCoreBrandError } from '@appscore/web-components/BrandError';

const BrandError = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppsCoreBrandError />
    </ThemeProvider>
  );
};

BrandError.propTypes = {};

export default BrandError;
