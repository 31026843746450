import axios from '@app/services/axios';
import { BASE_URL } from '@app/utils/constants/service.constant';

async function getPartnerBranches({ params, config = {} }) {
  return await axios().get(`${BASE_URL}/partnerBranches`, {
    ...config,
    params,
  });
}

export default getPartnerBranches;
