import React from 'react';
import PartnerService from '@app/services/PartnerService';

const PartnerConfigContext = React.createContext({});

export const usePartnerConfig = () => {
  return React.useContext(PartnerConfigContext);
};

const PartnerConfigProviderComponent = ({ children }) => {
  const [partner, setPartner] = React.useState(null);
  const [isForcedPickupAddress, setIsForcedPickupAddress] = React.useState(false);
  const [hasPoDEnabled, setHasPoDEnabled] = React.useState(false);

  const fetchPartnerConfig = async partnerId => {
    const result = await PartnerService.getPartnerConfigurations({ partnerId });
    if (result?.data?.data != null) {
      const { isForcedPickupAddress, proofOfDelivery } = result.data.data;
      setIsForcedPickupAddress(isForcedPickupAddress);
      setHasPoDEnabled(proofOfDelivery?.pin?.enabled || false);
    }
  };

  React.useEffect(() => {
    if (partner) {
      fetchPartnerConfig(partner);
    }
  }, [partner]);

  return (
    <PartnerConfigContext.Provider
      value={{
        partner,
        setPartner,
        isForcedPickupAddress,
        hasPoDEnabled,
      }}
    >
      {children}
    </PartnerConfigContext.Provider>
  );
};

export const PartnerConfigProvider = PartnerConfigProviderComponent;
