import React from 'react';
import PropTypes from 'prop-types';

import { createIntlProvider, getMessages } from '@app/contexts/IntlProvider';
import { InitialDataProvider, initialDataShape } from '@app/contexts/InitialDataProvider';
import { ThemeProvider } from '@app/contexts/ThemeProvider';
import { QueryClientProvider } from '@app/contexts/QueryClientProvider';
import {
  usePublicEnv,
  PublicEnvProvider,
  publicEnvShape,
  getPublicEnv,
} from '@app/contexts/PublicEnv';
import { setInstance } from '@app/tracking';
import MyShipments from '@app/pages/MyShipments/Loadable';
import { PartnerConfigProvider } from './contexts/PartnerProvider';

const IntlProvider = props => {
  const { locale } = usePublicEnv();
  return createIntlProvider(locale, getMessages)(props);
};

const Root = ({
  context,
  messages,
  initialData,
  onLoaded = () => {},
  onContactSupportRequest = (orderId, contactSupportUrl) => {},
  disableContactSupportRequest,
  track,
}) => {
  React.useEffect(() => {
    if (initialData) {
      setInstance(track, initialData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData]);

  return (
    <PublicEnvProvider context={context} getPublicEnv={getPublicEnv}>
      <InitialDataProvider data={initialData}>
        <ThemeProvider>
          <IntlProvider initialMessages={messages}>
            <PartnerConfigProvider>
              <QueryClientProvider>
                <MyShipments
                  onLoaded={onLoaded}
                  onContactSupportRequest={onContactSupportRequest}
                  disableContactSupportRequest={disableContactSupportRequest}
                />
              </QueryClientProvider>
            </PartnerConfigProvider>
          </IntlProvider>
        </ThemeProvider>
      </InitialDataProvider>
    </PublicEnvProvider>
  );
};

const ssrProps = typeof window !== 'undefined' ? window.__INITIAL_DATA__ : {};

Root.defaultProps = { ...ssrProps };

Root.propTypes = {
  context: PropTypes.shape(publicEnvShape),
  messages: PropTypes.shape({}),
  name: PropTypes.string.isRequired,
  initialData: PropTypes.shape(initialDataShape),
  onLoaded: PropTypes.func.isRequired,
};

export default Root;
