import React from 'react';
import Loadable from 'react-loadable';
import Loader from './components/Loader';
import ContentWrapper from './styled-components/ContentWrapper';

const MyShipmentsLoadable = Loadable({
  loader: () => import('./index'),
  loading() {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  },
});

export default MyShipmentsLoadable;
