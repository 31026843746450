import React from 'react';
import { QueryClient, QueryClientProvider as Provider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const QueryClientProvider = ({ children }) => {
  return <Provider client={queryClient}>{children}</Provider>;
};
