import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import theme from '@appscore/web-components/theme';
import { BrandError } from '@appscore/web-components/BrandError';

export const ErrorBoundary = ({ error, ...props }) => {
  // TODO: Error reporting

  return (
    <ThemeProvider theme={theme}>
      <BrandError {...props} />
    </ThemeProvider>
  );
};

ErrorBoundary.propTypes = {
  error: PropTypes.instanceOf(Error),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};
