const theme = {
  colors: {
    default: {
      main: '#100423',
      hover: '#100423',
      text: '#FFFFFF',
    },
    primary: {
      main: '#FA0050',
      hover: '#EA044E',
      text: '#FFFFFF',
    },
    secondary: {
      main: '#2D86F4',
      hover: '#2D86F4',
      text: '#FFFFFF',
    },
    success: {
      darkest: '#01C98C',
      dark: '#01C98C',
      medium: '#01C98C',
      light: '#01C98C',
      lightest: '#01C98C',
      main: '#01C98C',
      text: '#273239',
    },
    informative: {
      darkest: '#878290',
      dark: '#878290',
      medium: '#878290',
      light: '#EFEDF0',
      lightest: '#EFEDF0',
      main: '#878290',
      text: '#273239',
    },
    warning: {
      darkest: '#FFB526',
      dark: '#FFB526',
      medium: '#FFB526',
      light: '#FAF3A2',
      lightest: '#FAF3A2',
      main: '#FFB526',
      text: '#100423',
    },
    error: {
      darkest: '#C20018',
      dark: '#C20018',
      medium: '#C20018',
      light: '#FEE1E0',
      lightest: '#FEE1E0',
      main: '#C20018',
      text: '#273239',
    },
    neutral: {
      darkest: '#000000',
      dark: '#273239',
      medium: '#767F86',
      light: '#A9B1B7',
      lightest: '#FFFFFF',
      grayText: 'rgb(118, 127, 134)',
      gray: 'rgb(247, 247, 247)',
      black: 'rgb(39, 50, 57)',
    },
    text: {
      main: '#273239',
      label: '#767F86',
      primary: '#FA0050',
      secondary: '#100423',
    },
    border: {
      main: '#DDE6EE',
    },
    hover: {
      dark: 'rgba(0,0,0,0.05)',
      light: 'rgba(255,255,255,0.2)',
    },
    background: {
      light: 'rgb(247, 248, 249)',
    },
  },
  breakpoints: {
    xs: '0px',
    sm: '640px',
    md: '960px',
    lg: '1280px',
    xl: '1600px',
  },
  spaces: {
    none: '0px',
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '20px',
  },
  sizes: {
    main: {
      xs: '12px',
      sm: '16px',
      md: '20px',
      lg: '24px',
      xl: '28px',
    },
    radius: {
      xs: '2px',
      sm: '4px',
      md: '6px',
      lg: '8px',
      xl: '10px',
    },
    component: {
      small: '24px',
      medium: '32px',
      large: '40px',
    },
  },
  shadows: {
    main: '0 2px 6px 0 rgba(39, 50, 57, 0.15)',
    card: '0px 2px 6px rgba(0, 0, 0, 0.2)',
  },
  typography: {
    fontFamilies: {
      muli: '"Muli", sans-serif',
      mulish: '"Mulish", sans-serif',
      lato: '"Lato", sans-serif',
    },
    fontWeights: {
      thin: 100,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      bolder: 900,
    },
    fontSizes: {
      xs: '8px',
      sm: '10px',
      md: '12px',
      lg: '14px',
      xl: '16px',
      xxl: '18px',
    },
    default: {
      fontFamily: '"Muli", sans-serif',
      fontWeight: 'normal',
      lineHeight: '1.3',
      fontSize: '14px',
    },
  },
};

export default theme;
